import React from "react"
import Layout from "../components/_App/layout"
import Seo from "../components/_App/seo"
import Navbar from "../components/_App/Navbar"
import Footer from "../components/_App/Footer"

import Shape1 from "../assets/images/shape1.png"
import Shape2 from "../assets/images/shape2.svg"
import { graphql, Link, useStaticQuery } from "gatsby"

const query = graphql`
  {
    allStrapiServices(filter: { section: { eq: "ServicesPage" } }) {
    nodes {
      title
      section
      shortText
      slug
      icon
      image {
          childImageSharp {
            fluid(jpegProgressive:true, maxWidth:650, webpQuality: 80, jpegQuality: 80) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
    }
  }
  }
`

const Services = () => {

  const data = useStaticQuery(query)
  const { allStrapiServices } = data

  return (
    <Layout>
      <Seo title="Services" />

      <Navbar />

      {/*<PageBanner pageTitle="Services" />*/}

      <div className="ml-services-area pt-120-b-80">
        <div className="container">
          <div className="section-title">
            {/*<span className="sub-title">Our Solutions</span>*/}
            <h1>Our Services</h1>
            <div className="bar"/>
            <p>Services we Offer</p>
          </div>

          <div className="row justify-content-center">
            {
              allStrapiServices.nodes.map((item, index) => {
                return (
                  <div className="col-lg-4 col-sm-6 mb-3 service-box px-2" key={index}>
                    <Link to={`/services/${item.slug}/`}  style={{ height: '100%', display: 'block' }}>
                      <div className="single-ml-services-box" style={{ height: '100%', display: 'block' }}>
                      <div className="image">
                        <img src={item?.image?.childImageSharp.fluid.srcWebp} width={70} style={{maxHeight: 70}} alt={item.title} />
                      </div>
                      <h3>
                        {item.title}
                      </h3>
                      <p>
                        {item.shortText}
                      </p>
                    </div>
                    </Link>
                  </div>
                )
              })
            }

          </div>
        </div>

        {/* Shape Images */}
        <div className="shape1">
          <img src={Shape1} width={200} height={200} alt="shape" />
        </div>
        <div className="shape2 rotateme">
          <img src={Shape2} width={22} height={22} alt="shape" />
        </div>
        <div className="shape8 rotateme">
          <img src={Shape2} width={22} height={22} alt="shape" />
        </div>
      </div>

      <Footer />
    </Layout>
  )
}

export default Services
